<template>
    <div class="fourth" :class="offLamp ? 'on' : null">
    
        <div class="page-1">
            <span class="btn-lamp" :style="lampStype" @click="offLamp=!offLamp"></span>
            <!-- <img class="imgClass" v-if="isShowBtn" :style="checkToOrderStyle" @click="checkUser(1)" src="./../../assets/img/dissDao/4/Check_to_order.png" alt="" srcset=""> -->

            <a href="javascript:;" @click="awaken" class="awakenClass">
				<img v-if="off" :style="musicImgH" src="@/assets/img/dissDao/music_off.png" alt="">
				<img v-else :style="musicImgH" src="@/assets/img/dissDao/music.png" alt="">
			</a>

            <div class="fourth-info" v-if="userInviteId">
                <el-alert
                    :title="title"
                    type="warning">
                </el-alert>
                
            </div>

        </div>
        <div class="connect-wallet" :style="connectWalletStyle">
            <el-popconfirm v-if="isShowBtn" title="Confirm to disconnect the wallet?" @confirm="disconnect">
                <template #reference>
                    <span class="accountSpan" :style="connectWalletStyle">{{accountTxt(account.toLowerCase())}}</span>
                </template>
            </el-popconfirm>
            
            <img v-else @click="connectWalletEvent" :style="connectWalletStyle" src="./../../assets/img/dissDao/4/connect-wallet.png" alt="">

            <div v-if="isShowBtn">
                <img :style="{width:connectWalletStyle.width}" @click="mintDialogEvent" class="mint" src="./../../assets/img/dissDao/4/Mint.png" alt="">
                <span class="accountSpan" :style="connectWalletStyle" @click="dialogActive = !dialogActive && init()">
                    <img class="cu_p" src="@/assets/img/dissDao/goods.png" :style="connectWalletStyle" alt="" srcset="">
                </span>
            </div>
        </div>

        <div class="dialog-mint" v-if="dialogMint">
            <div class="dialog-mint-con">
                <el-icon class="dialog-close" @click="dialogMint = !dialogMint"><Close /></el-icon>
                <div class="dialog-mint-list">
                    <!-- <img class="cu_p" src="@/assets/img/dissDao/input_1.png" alt="" srcset=""> -->
                    <input type="text" class="input_1" v-model="invitationCode" :class="invitationCode?'on':''">
                    <img class="cu_p" src="@/assets/img/dissDao/mint.png" alt="" srcset="" @click="mintEvent(true)">
                </div>
                <div class="dialog-mint-list">
                    <img class="cu_p" src="@/assets/img/dissDao/input_2.png" alt="" srcset="" @click="mintEvent()">
                </div>
            </div>
        </div>

        <div class="dialog" v-show="dialogActive">
            <div class="dialog-con" @click.stop="dialogActive = !dialogActive">
                <div @click.stop="" class="dialog-main">
                    <ul>
                        <li v-for="(item, i) in imgList" :key="i">
                            <img :src="item.image" alt="">
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="mint_succ" v-if="mintSucc">
            <img src="@/assets/img/dissDao/mint_succ.png" alt="">
        </div>

    </div>
</template>

<script>
    import axios from 'axios'
    import { Close } from '@element-plus/icons-vue'
    import { getContract, mint, getPrice, getTotal, checkUser, balanceOf, tokenOfOwnerByIndex } from './../../utils/contract'
    import { ElLoading, ElMessage, ElMessageBox } from 'element-plus'
    import { accountTxt, copyTxt } from "./../../utils"
    export default {
        data() {
            return {
                offLamp: true, // 灯光开关

                connectWalletStyle: {}, 
                lampStype: {},
                enterIdStyle: {},
                checkToOrderStyle: {},
                musicImgH: {},

                userPrice: '', // 普通用户铸造价格
                vipPrice: '', // 白名单用户铸造价格
                vipUser: false, // 是否是白名单
                imgList: {}, // 拥有的NFT展示列表
                dialogActive: false,
                mintSucc: false, // mint 成功后提示

                newTime: 0,

                invitationCode: '', // 邀请码
                userInviteId: '', // 我的邀请码
                dialogMint: false,

                title: ``
            }
        },
        components: {
            Close,
        },
        computed: {
            off() {
				return this.$store.state.off;
			},
            account() {
                return this.$store.state.web3Modal.account;
            },
            network() {
                let network = this.$store.state.web3Modal.network || {};
                return network;
            },
            networkError() {
                let networkError;
                if(!this.account){
                    return false
                }
                if(this.network.chainId == process.env.VUE_APP_NETWORK_CHAINID){
                    networkError = false;
                }else{
                    networkError = true;
                }
                return networkError
            },
            isShowBtn() {
                if(this.account && !this.networkError){
                    return true
                }else{
                    return false
                }
            }
        },
        watch: {
            account(newValue, oldValue) {
                this.init();
            }
        },
        methods: {
            copyTxt,
            accountTxt,
            // 🎵开关
			awaken(){
				this.$store.dispatch('awaken')
			},
            getValue(val, type) {
                const body_h = document.getElementsByTagName('body')[0].clientHeight;
                return type ? body_h * (val/1177) : body_h * (val/1440) + 'px'
            },
            initStyle() {
                this.connectWalletStyle = {
                    width: this.getValue(271),
                    height: this.getValue(64),
                    lineHeight: this.getValue(64),
                    left: this.getValue(10),
                    top: this.getValue(130),
                }
                this.lampStype = {
                    width: this.getValue(331),
                    height: this.getValue(215),
                    left: this.getValue(245),
                    // left: this.getValue(455),
                }
                this.enterIdStyle = {
                    width: this.getValue(271),
                    height: this.getValue(64),
                    right: this.getValue(566),
                }
                this.checkToOrderStyle = {
                    width: this.getValue(352),
                    height: this.getValue(63),
                    right: this.getValue(134),
                },
                this.musicImgH = {
                    height: this.getValue(88)
                }
            },
            connectWalletEvent() {
                this.$store.dispatch('connect');
                console.log('this.network', this.network)
                // 网络错误
                if(this.networkError){
                    ElMessage({
                        message: 'Network error, please select the correct network！',
                        type: 'error',
                    });
                    this.$store.dispatch('disconnect')
                }
                else{
                    if(this.account){
                        this.init();
                    }
                }
            },
            disconnect() {
                this.$store.dispatch('disconnect')
            },
            // mint 弹框
            mintDialogEvent() {
                this.dialogMint = true
            },
            // 铸造NFT
            async mintEvent(status) {
                if(status && this.invitationCode ===''){
                    ElMessage({
                        message: 'Invitation id cannot be empty',
                        type: 'error',
                    });
                    return false;
                }
                const loading = ElLoading.service({
                    lock: true,
                    text: 'mint Loading',
                    background: 'rgba(0, 0, 0, 0.7)',
                })
                try {
                    console.log('123')
                    await this.getPrice();
                    // await this.checkUser();
                    let price = this.userPrice;
                    console.log('this.vipPrice', this.userPrice);
                    console.log('price', price);

                    const Contract = await getContract();
                    console.log('this.invitationCode', this.invitationCode);
                    console.log('Contract', Contract);
                    Contract.methods.mint(this.invitationCode || 0)
                    .send({
                        value: price,
                        from: localStorage.getItem('account')
                    })
                    .then(res=>{
                        console.log('mint res', res);
                        loading.close();
                        // ElMessage({
                        //     message: 'mint success',
                        //     type: 'success',
                        // });
                        this.mintSucc = true;
                        setTimeout(() => {
                            this.mintSucc = !true;
                        }, 3000);
                        this.getInvitationCode(true) // 邀请码
                    })
                    .catch(err=>{
                        console.error('err', err)
                        loading.close()
                    })
                } catch (error) {
                    loading.close()
                }
            },
            // 获取用户mini价格，普通用户价格和白名单用户价格
            async getPrice() {
                const Contract = await getContract();
                console.log('getPrice', Contract)
                await Contract.methods
                .getPrice()
                .call()
                .then(res=>{
                    console.log('getPrice res', res)
                    this.userPrice = res;
                })
                .catch(err=>{
                    console.error('err', err)
                })
            },
            // 获取邀请码
            async getInvitationCode(isShow){
                const Contract = await getContract();
                console.log('Contract.methods', Contract.methods)
                await Contract.methods
                .userInviteId(localStorage.getItem('account'))
                .call()
                .then(res=>{
                    console.log('userInviteId res', res)
                    if(res == 0){
                        return false
                    }

                    this.userInviteId = res
                    if(isShow){
                        ElMessageBox.alert(`${res}`, 'InvitationID', {
                            confirmButtonText: 'yes',
                        });
                    }
                    this.title = `Hi, please share with your friends.  For every 5 friends you invite, you will get a rare NFT, and this rare NFT may airdrop doomer coin to you. Project website:doomer.org Invite ID: ${res} `
                    document.getElementsByClassName('el-alert__title')[0].innerHTML = `Hi, please share with your friends.  For every 5 friends you invite, you will get a rare NFT, and this rare NFT may airdrop doomer coin to you. Project website:dissdao.org Invite ID: ${res}  <span id="copy">Copy</span>`
                    document.getElementById('copy').addEventListener('click',(e)=>{
                        this.copyTxt(res);
                    })
                })
                .catch(err=>{
                    console.error('err', err)
                })
            },
            // 验证是否是白名单用户
            async checkUser(el) {
                let newTime = +new Date()
                if(newTime-3000 < this.newTime){
                    return;
                }
                this.newTime = newTime
                console.log(this.account)
                const Contract = await getContract();
                await Contract.methods
                .checkUser(this.account)
                .call()
                .then(res=>{
                    console.log('checkUser    res', res)
                    this.vipUser = res;
                    if(el){
                        ElMessage({
                            message: res ? 'White list users！' : 'Ordinary users！',
                            type: 'success',
                        });
                    }
                })
                .catch(err=>{
                    console.error('err', err)
                })
            },
            // 查询用户有多少NFT
            async balanceOf() {
                const Contract = await getContract();
                await Contract.methods
                .balanceOf(localStorage.getItem('account'))
                .call()
                .then(res=>{
                    console.log('balanceOf res', res)
                    if(res && res > 0){
                        for(let i=0; i<res; i++){
                            this.tokenOfOwnerByIndex(i);
                        }
                    }
                })
            },
            // 获取用户 id
            async tokenOfOwnerByIndex(i) {
                const Contract = await getContract();
                await Contract.methods
                .tokenOfOwnerByIndex(localStorage.getItem('account'), i)
                .call({
                    from: localStorage.getItem('account'),
                })
                .then(res=>{
                    console.log('tokenOfOwnerByIndex res', res)
                    this.tokenURI(res, i);
                })
            },
            // 根据 tokenOfOwnerByIndex 获取的 id 得到图片
            async tokenURI(id, i) {
                const Contract = await getContract();
                await Contract.methods
                .tokenURI(id)
                .call()
                .then(res=>{
                    // console.log('tokenURI res', res)
                   
                    axios(res).then(json=>{
                        //  console.log('json', json)
                        this.imgList[i] = json
                    })
                    
                })
            },
            async init(){
                this.imgList={}
                console.log('init')
                this.balanceOf();
                this.getInvitationCode()
            }
        },
        async mounted () {
            this.initStyle();
            window.onresize = ()=>{
                this.initStyle();
            };
            await this.$store.dispatch('init');
            if(this.account){
                this.init();
            }
        },
    }
</script>

<style lang="less" scoped>
    .fourth{
        width: 100%;
        min-width: 1200px;
		min-height: 100vh;
        position: relative;
        overflow: hidden;
        background: url("./../../assets/img/dissDao/4/2..png") no-repeat center top,url("./../../assets/img/dissDao/4/1..png") no-repeat center top;
        background-size: cover;
        &.on{
            background: url("./../../assets/img/dissDao/4/3..png") no-repeat center top,url("./../../assets/img/dissDao/4/2..png") no-repeat center top,url("./../../assets/img/dissDao/4/1..png") no-repeat center top;
            background-size: cover;
        }
        .page-1{
            width: 1200px;
            margin: 0 auto;
            min-height: 100vh;
            position: relative;
            .btn-lamp{
                width: 331px;
                height: 215px;
                position: absolute;
                left: 0;
                top: 0;
                cursor: pointer;
            }
            .imgClass{
                position: absolute;
                bottom: 30px;
                cursor: pointer;
            }

            .awakenClass{
                position: fixed;
                left: 12px;
                bottom: 70px;
            }
        }
        &-info{
            position: absolute;
            width: 800px;
            height: 200px;
            left: 50%;
            margin-left: -400px;
        }
        .connect-wallet{
            position: absolute;
            left: 10px;
            top: 130px;
            cursor: pointer;
            .accountSpan{
                background: url('./../../assets/img/dissDao/4/connect_wallet_bg.png') no-repeat center;
                background-size: 100%;
                display: inline-block;
            }
            .mint{
                cursor: pointer;
                margin-top: 12px;
            }
        }
        .dialog-mint{
            position: fixed;
            left: 0;
            top: 0;
            width: 100vw;
            height: 100vh;
            display: flex;
            align-items: center;
            justify-content: center;
            &-con{
                width: 964px;
                height: 584px;
                background: #fff;
                box-sizing: border-box;
                padding-top: 20px;
                position: relative;
                .dialog-close{
                    cursor: pointer;
                    font-size: 32px;
                    position: absolute;
                    right: 20px;
                    top: 20px;
                }
            }
            &-list{
                text-align: left;
                padding-left: 125px;
                padding-top: 115px;
                display: flex;
                .input_1{
                    width: 447px;
                    height: 63px;
                    border: none;
                    background: url('./../../assets/img/dissDao/input_1.png') no-repeat;
                    background-size: cover;
                    margin-right: 50px;
                    font-size: 24px;
                    box-sizing: border-box;
                    padding-left: 20px;
                    &.on{
                        background: url('./../../assets/img/dissDao/input_1_on.png') no-repeat;
                    }
                    &:focus{
                        background: url('./../../assets/img/dissDao/input_1_on.png') no-repeat;
                    }
                }
            }
        }
        .dialog{
            position: fixed;
            left: 0;
            top: 0;
            width: 100vw;
            height: 100vh;
            &-main{
                width: 1180px;
                min-height: 500px;
                max-height: 800px;
                overflow: auto;
                background: #fff;
                border-radius: 10px;
                border: 3px solid #0e050a;
                box-sizing: border-box;
                padding: 20px 50px;
            }
            &-con{
                display: flex;
                width: 100vw;
                height: 100vh;
                justify-content: center;
                align-items: center;
                ul{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: flex-start;
                    li{
                        margin-right: 20px;
                        margin-bottom: 20px;
                        width: 156px;
                        height: 156px;
                        border: 3px solid #0e050a;
                        &:nth-child(6n){
                            margin-right: 0px;
                        }
                        img{
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }
                }
            }
        }
        .mint_succ{
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
                width: 1200px;
            }
        }
        .cu_p{
            cursor: pointer;
        }
    }
    @media (min-width: 1500px) {
        .fourth{
            .page-1{
                .btn-lamp{
                    height: 140px !important;
                    left: 236px !important;
                }
            }
        } 
    }
</style>